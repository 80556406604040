<template>
  <div class="column pt-0">
    <h2 class="is-size-5 has-text-weight-semibold mb-4">Список заводов</h2>
    <BaseTable
      :data="factories"
      :columns="columns"
      :loading="isLoading"
      :total="total"
      :per-page="perPage"
      :current-page="currentPage"
      @change="fetchFactories"
      @rowClick="onRowClick"
    />
  </div>
</template>


<script>
import BaseTable from "@/components/Base/Table";

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      factories: [],
      total: 0,
      isLoading: true,
      isActive: false,
      currentPage: 1,
      perPage: 10,
      columns: [
        {
          field: "id",
          label: "ID",
          width: "40",
          numeric: true,
        },
        {
          field: "branch.company.name",
          label: "Компания",
          centered: true,
        },
        {
          field: "name",
          label: "Завод/Фабрика/Цех",
        },
      ],
    };
  },
  mounted() {
    this.fetchFactories();
  },
  methods: {
    async fetchFactories(page) {
      this.currentPage = page || 1
      this.isLoading = true;
      try {
        const { data } = await this.$axios.get(`admin/factories/paginate/${(this.currentPage - 1) * 10}/${this.perPage}`)
        this.factories = data?.data || [];
        this.total = data?.count;
      } catch (e) {
        throw new Error(e)
      }
      this.isLoading = false;
    },
    onRowClick(item) {
      this.$router.push(`/factory-editor/${item.id}`);
    },
  },
};
</script>


<style>
.main-set {
  margin-top: 80px;
  background-color: white;
  padding: 50px;
}

.pagination span {
  padding-left: 7px;
  padding-right: 7px;
  background-color: red;
}
</style>
